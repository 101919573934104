.button__bar {
    display: inline-block;
    vertical-align: middle;
    margin: auto;
    padding: 0;

    text-align: center;
  }
  
  .button__bar li {
    list-style: none;
    cursor: pointer;
    display: inline-block;
    margin: 0 3px;
    padding: 0;
  }
  
  .button__bar li button {
    border: none;
    background: rgba(255, 255, 255, 0.3);
    color: transparent;
    cursor: pointer;
    display: block;
    height: 15px;
    width: 15px;
    border-radius: 7.5px;
  }
  
  .button__bar li.slick-active button {
    background-color: #FFD415;
  }
  
  .button__bar li button:hover,
  .button__bar li button:focus {
    background: rgba(247, 34, 34, 0.8);
    outline: 0;
  }
  
  .slick-slide img {
    display: block;
    margin: auto;
  }
  