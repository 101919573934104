/* fonts.css */
@font-face {
    font-family: 'Rodina-Regular';
    src: url('./rodina-regular.woff.ttf') format('woff2'), /* primeramente intenta con WOFF2, que es más eficiente */
         url('./rodina-regular.woff.ttf') format('woff'); /* si WOFF2 no es soportado, intenta con WOFF */
  }
  

  @font-face {
    font-family: 'atomic-regular';
    src: url('./set-sail-studios-atomic-marker-regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'atomic';
    src: url('./set-sail-studios-atomic-marker-extras.otf') format('opentype');
    font-weight: 800;
    font-style: normal;
  }
