/* customScrollbar.css */
::-webkit-scrollbar {
    width: 12px;
}

::-webkit-scrollbar-track {
    background: #1a1919;
}

::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
    border: 2px solid #888;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #888;
}
